import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link, Button, Layout, PropertyItem, Carousel, GlobalImageAndText } from "components";
import { Hero } from "components/Acf/components";
import "./agent.scss";

const AgentPage = ({ data, location, pageContext }) => {
  const { agent, properties } = data;

  const { title, featuredImage, content, teamMemberDetails, departments, seo } = agent;

  const { propertyList = [] } = properties;

  const availPropertiesCarouselSettings = {
    id: "agent-carousel",
    slidesPerView: 1,
    spaceBetween: 16,
    grabCursor: false,
    pagination: {
      clickable: true,
    },
    navigation: false,
    breakpoints: {
      740: {
        navgiation: false,
        slidesPerView: 2,
      },
      1080: {
        navgiation: false,
        slidesPerView: propertyList.length > 3 ? 3 : propertyList.length,
      },
    },
  };

  const testimonialCarouselSettings = {
    id: "testimonial-carousel",
    slidesPerView: 1,
    spaceBetween: 0,
    grabCursor: false,
    pagination: true,
    navigation: false,
    speed: 2000,
    autoplay: {
      delay: 5000,
    },
  };

  return (
    <Layout pageTitle={`${title} | Davey Real Estate` || `Agent | Davey Real Estate`} seo={seo}>
      <Hero
        data={{
          breadcrumbs: "above",
          compact: true,
          content: `<h1>OUR TEAM</h1>`,
        }}
        location={location}
      />
      <section className="agent-profile--component layout">
        <div className="agent-profile--inner">
          <div className="image">
            <GatsbyImage image={featuredImage ? getImage(featuredImage.node.localFile) : ""} alt={title} />
          </div>
          <div className="content">
            <div className="name">{title}</div>
            {departments && <div className="position">{teamMemberDetails.teamMemberJobPosition}</div>}
            <div className="contact-details">
              {teamMemberDetails?.teamMemberPhoneNumber && (
                <div className="agent-contact">
                  <span className="agent-contact__heading">P:</span>
                  <a href={`tel:${teamMemberDetails.teamMemberPhoneNumber}`}>
                    {teamMemberDetails.teamMemberPhoneNumber}
                  </a>
                </div>
              )}
              {teamMemberDetails?.teamMemberMobileNumber && (
                <div className="agent-contact">
                  <span className="agent-contact__heading">M:</span>
                  <a href={`tel:${teamMemberDetails.teamMemberMobileNumber}`}>
                    {teamMemberDetails.teamMemberMobileNumber}
                  </a>
                </div>
              )}
              {teamMemberDetails?.teamMemberEmailAddress && (
                <div className="agent-contact">
                  <span className="agent-contact__heading">E:</span>
                  <a href={`mailto:${teamMemberDetails.teamMemberEmailAddress}`}>
                    {teamMemberDetails.teamMemberEmailAddress}
                  </a>
                </div>
              )}
            </div>
            {content && <div className="profile" dangerouslySetInnerHTML={{ __html: content }}></div>}
          </div>
          <div className="buttons">
            <div className="button-wrapper">
              <Link to="/why-davey/our-team/">
                <Button leftIcon="ArrowLeft">{`Back to team`}</Button>
              </Link>

              <Link to="/market-appraisal/">
                <Button variant="outline" colorScheme="quinary">
                  Get an appraisal
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="agent-profile--info-block layout">
        {teamMemberDetails?.testimonials && Object.keys(teamMemberDetails?.testimonials).length > 0 && (
          <div className="agent-profile__testimonials">
            <h3 className="typography--h3">Testimonials</h3>
            {teamMemberDetails?.testimonials.length > 0 && (
              <Carousel swiperSettings={testimonialCarouselSettings}>
                {teamMemberDetails.testimonials?.map((testimonial, index) => {
                  return (
                    <div key={index}>
                      <div dangerouslySetInnerHTML={{ __html: testimonial.testimonial }} />
                    </div>
                  );
                })}
              </Carousel>
            )}
          </div>
        )}

        {Object.keys(propertyList).length > 0 && (
          <div className="agent-profile__properties">
            <h3 className="typography--h3">Current listings</h3>
            <Carousel swiperSettings={availPropertiesCarouselSettings}>
              {propertyList?.map((property) => {
                return <PropertyItem property={property} minimal key={property.id} />;
              })}
            </Carousel>
            <Link to={`/properties/${agent.slug}/`} className="agent-profile__properties__btn">
              <Button variant="solid">View All</Button>
            </Link>
          </div>
        )}
      </section>

      <GlobalImageAndText />
    </Layout>
  );
};

export default AgentPage;

export const AgentQueryById = graphql`
  query AgentQueryById($id: String!, $slug: String!) {
    properties: allWpProperty(filter: { agents: { nodes: { elemMatch: { slug: { eq: $slug } } } } }, limit: 3) {
      propertyList: nodes {
        ...PropertyFragment
      }
    }
    # selecting the current page by id
    agent: wpTeam(id: { eq: $id }) {
      id
      slug
      title
      content
      slug
      featuredImage {
        node {
          title
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED, width: 300)
            }
          }
        }
      }
      teamMemberDetails {
        teamMemberEmailAddress
        teamMemberJobPosition
        teamMemberMobileNumber
        teamMemberPhoneNumber
        testimonials {
          testimonial
        }
      }
      departments {
        nodes {
          slug
          name
        }
      }
    }
  }
`;
